.cart {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cart .btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 50%;
    margin: 0 auto;
    padding: 10px 20px;
    max-width: 1200px;
    border-radius: 10px;
    margin: 20px;
}

.cart .btns button {
    min-width: 150px;
    height: 50px;
    border: none;
    border-radius: 5px;
    background-color: var(--col1);
    color: white;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    color: white;
    margin: 5px;
}

.cart .btns button:hover {
    background-color: var(--col2);
    color: white;
}
