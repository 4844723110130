/* Container for sidebar and content */
.container {
    display: flex;
    font-family: Arial, sans-serif;
  }
  
  /* Sidebar styling */
  /* Sidebar styling */
.sidebar {
    width: 250px;
    background-color: white;
    padding-top: 20px;
    position: fixed;
    height: 100%;
  }
  
  .sidebar a {
    display: block;
    color: black;
    padding: 10px 15px;
    text-decoration: none;
    position: relative; /* Relative positioning for the hover effect */
    transition: all 0.3s ease; /* Smooth transition for hover effect */
  }
  
  /* On hover, add a border-left line */
  .sidebar a:hover {
    background-color: white;
    border-left: 4px solid black; /* White border on the left */
    padding-left: 11px; /* Adjust padding to compensate for the border */
  }
  
  /* Optional: Make the border appear with a smooth transition */
  .sidebar a::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    background-color: black;
    transition: width 0.3s ease;
  }
  
  .sidebar a:hover::before {
    width: 4px; /* Make the border visible when hovered */
  }
  
  
  /* Main content styling */
  .content {
    margin-left: 250px;
    padding: 20px;
    background-color: white;
    height: 100vh;
    overflow-y: scroll;
  }
  
  /* Section styling */
  .section {
    padding: 20px;
    margin-bottom: 50px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .section h2 {
    margin-top: 0;
    font-weight: bold;
    font-size: 18px;
     /* Bold headings */
  }
  
  .section h3 {
    font-weight: bold; /* Bold sub-headings */
  }
  
  /* Paragraph styling */
  .section p {
    line-height: 1.6;
    font-size: 16px;
    margin: 15px 0; /* Add space between paragraphs */
    color: #333; /* Darker text color for paragraphs */
  }
  
  /* List styling */
  .section ul {
    padding-left: 20px;
  }
  
  .section ul li {
    margin-bottom: 10px;
    font-size: 15px;
  }
  
  /* Smooth scrolling */
  html {
    scroll-behavior: smooth;
  }
  .section h2 {
    font-weight: bold;
  }
  
  .section h3 {
    font-weight: bold;
  }
  .section p {
    line-height: 1.6;
    font-size: 16px;
    margin: 15px 0;
    color: #333;
  }
      