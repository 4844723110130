nav {
    display: flex;
    flex-direction: column;
    height: 50%;
    /* padding: 10px; */
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

nav svg {
    width: 25px;
    height: 25px;
    cursor: pointer;
} 

.nav svg:hover {
    color: var(--col1);
}
nav .heading{
    font-size: 18px;
    font-weight: 600;
    display: inherit;
    width: 100%;
    height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
}
nav .s1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* nav img {
    width: 150px;
} */

nav .searchbar {
    display: flex;
    border: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
    padding: 5px;
    justify-content: space-between;
    width: 50%;
}

nav .searchbar input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 14px;
}

nav .searchbar svg {
    color: var(--col1);
}

nav .searchbar svg:hover {
    background-color: var(--col1);
    color: white;
    border-radius: 50%;
    padding: 5px;
}



nav .searchbar button {
    border: none;
    background-color: transparent;
    cursor: pointer;

}

nav .s1 .right {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

nav .cart {
    position: relative;
    margin: 0px 20px;
    color: rgb(109, 109, 109);
}

nav .cart .qty {
    background-color: var(--col1);
    color: white;
    border-radius: 50%;
    /* padding: 1px; */
    position: absolute;
    width: 25px;
    height: 25px;
    top: -15px;
    right: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-align: center;
}

nav .s2{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    gap: 3%;
}

nav .s2 a{
    text-decoration: none;
    color: black;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

nav .s2 a:hover{
    color: var(--col1);
}


nav .s3 {
    display: none;
}
