/* Header Styles */
.main-header {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .col-nav {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .btn-mobile {
    display: none;
  }
  
  .nav {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
  
  .nav-item {
    margin-left: 15px;
  }
  
  .dropdown-toggle {
    display: flex;
    align-items: center;
    margin-left: -1em;
  }
  
  .img-xs {
    width: 40px;
    height: 40px;
  }
  
  .dropdown-menu {
    min-width: 200px;
    margin-left: -1em;
  }
  
  @media (max-width: 768px) {
    .btn-mobile {
      display: block;
    }
  
    .main-header {
      padding: 10px;
      flex-direction: column;
      align-items: flex-start;
    }
  
    .col-nav {
      width: 100%;
      justify-content: space-between;
    }
  
    .nav {
      width: 100%;
      justify-content: space-between;
    }
  
    .nav-item {
      margin-left: 0;
    }
  
    .dropdown-menu {
      width: 100%;
    }
  }
  