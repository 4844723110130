.footer {
  
    color: #fff; /* Change the text color as needed */
    padding: 20px 20px; /* Adjust padding as needed */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start; /* Align items at the top for better layout on smaller screens */
}

/* Flexbox layout for desktop */
.footer-item {
    flex: 1 1 200px; /* Adjust the width of footer items */
    margin: 0 10px; /* Adjust margin between items */
}

/* Footer logo */
.footer-logo {
    height: 80px;
    width: 80px; /* Adjust the width of the logo as needed */
}

/* Footer headings */
.footer-heading {
    font-size: 18px; /* Adjust heading font size as needed */
    margin-bottom: 10px; /* Adjust margin bottom as needed */
}

/* Footer lists */
.footer-list {
    list-style: none;
    padding: 0;
}

.footer-list-item {
    margin-bottom: 5px; /* Adjust margin bottom as needed */
}

/* Responsive for tablets and smaller screens */
@media screen and (max-width: 1024px) {
    .footer {
        justify-content: center;
        text-align: center;
        padding: 20px;
    }

    .footer-item {
        flex: 1 1 150px;
        margin-bottom: 20px; /* Add margin for better spacing on smaller screens */
    }

    .footer-logo {
        height: 60px;
        width: 60px; /* Reduce logo size for tablets */
    }

    .footer-heading {
        font-size: 16px; /* Adjust heading font size for smaller screens */
    }
}

/* Responsive for mobile phones */
@media screen and (max-width: 768px) {
    .footer {
        flex-direction: column; /* Stack footer items vertically on mobile */
        align-items: center;
        padding: 15px;
    }

    .footer-item {
        flex: 1 1 auto;
        margin-bottom: 15px;
        text-align: center; /* Center the text for better alignment on mobile */
    }

    .footer-logo {
        height: 50px;
        width: 50px; /* Further reduce logo size for mobile */
    }

    .footer-heading {
        font-size: 14px; /* Smaller heading font size on mobile */
    }

    .footer-list-item {
        font-size: 14px; /* Adjust list item font size for mobile */
    }
}
