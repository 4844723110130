/* Commission Box Styles */
.commission-box {
    background-color: #2ab6ce; /* Light grey background */
    border: 1px solid #dee2e6; /* Subtle border */
    border-radius: 5px; /* Rounded corners */
    padding: 10px; /* Internal spacing */
    margin-top: 10px; /* Space above the box */
    font-size: 14px; /* Font size for text */
  }
  
  .commission-box p {
    margin: 0; /* Remove default margin */
    display: flex; /* Flexbox for alignment */
    align-items: center; /* Center align items */
  }
  
  .commission-box input[type="number"] {
    width: 60px; /* Fixed width for the input */
    margin-right: 8px; /* Space between input and text */
    border: 1px solid black; /* Border color */
    border-radius: 3px; /* Rounded corners */
    padding: 4px; /* Internal padding */
    font-size: 14px; /* Font size */
    color: black; /* Placeholder text color */
  }
  
  .commission-box input[type="number"]:focus {
    outline: none; /* Remove default outline */
    border-color: #80bdff; /* Blue border on focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25); /* Shadow on focus */
  }
  
  .commission-box input[type="number"]::placeholder {
    color: #adb5bd; /* Placeholder text color */
    font-style: italic; /* Italic style for placeholder */
  }
  