
.header-container {
    padding-top: 10px;
    background-color: #f3f4f6;
    margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  }
  
  .faq-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .steps-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
  }
  
  .step-card {
    width: 23%;
    background-color: white;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 15px;
    margin: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .step-circle {
    background-color:#f0c014;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-size: 18px;
    font-weight: bold;
  }
  
  .step-title {
    margin-top: 10px;
    font-weight: bold;
  }
  
  .popular-categories {
    padding: 20px;
    background-color: #f9fafb;
  }
  
  .categories-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 2px));
    margin-left: 60px;
    gap: 10px;
  }
  
  .category-card {
    background-color: white;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .benefits-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
  }
  
  .benefit-card {
    width: 30%;
    background-color: white;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.benefit-title {
    font-weight: bold;
    margin-top: 10px;
}
.faq-container {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
    width: 90%;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .faq-heading {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .faq-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .faq-item {
    margin-bottom: 15px;
  }
  
  .faq-question {
    font-size: 18px;
    background-color: #f0c014;
    color: white;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .faq-answer {
    padding: 10px;
    margin-top: 5px;
    background-color: #f1f1f1;
    border-left: 3px solid #f0c014;
    border-radius: 0 5px 5px 5px;
    color: #333;
  } 
  .seller-blogs {
    width: 90%;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    background-color:#f1f1f1;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .blog-heading {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .blog-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .blog-item {
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .blog-item h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color:#f0c014;
  }
  
  .blog-item p {
    font-size: 16px;
    margin-bottom: 10px;
    color: #555;
  }
  
  .payment-cycle-container {
    max-width: 90%;
    margin-top: 10px;
    margin: 0 auto;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #FFFAE3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 2px solid #f0c014;
  }
  
  .payment-cycle-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 2rem;
    /* text-shadow: 1px 1px 2px #333 ; */
  }
  
  .payment-cycle-section {
    margin-bottom: 1.5rem;
    padding: 15px;
    background-color: #FFF8DC;
    border-left: 6px solid #f0c014;
    border-radius: 8px;
  }
  
  .payment-cycle-subtitle {
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 1rem;
    /* text-shadow: 1px 1px 2px #333; */
  }
  
  .payment-cycle-subheading {
    font-size: 1.5rem;
    font-weight: 600;
    color:  #333;
    margin-bottom: 0.75rem;
    /* text-shadow: 1px 1px 2px  #333; */
  }
  
  .payment-cycle-text {
    font-size: 1.1rem;
    color:  #333;
    margin-bottom: 1rem;
    line-height: 1.6;
  }
  .payment-cycle-img {
    max-width: 250px; /* Adjust the size of the image */
    height: auto;
    margin-right: 100px; /* Space between text and image */
  }
  .payment-cycle-img1 {
    max-width: 350px; /* Adjust the size of the image */
    height: auto;
    margin-right: 100px; /* Space between text and image */
  }
  .payment-cycle-heading {
    display: flex;
    align-items: center;
    justify-content: space-between; /* This aligns heading to the left and image to the right */
  }
  .payment-cycle-img {
    max-width: 250px; /* Adjust the size of the image */
    height: auto;
    margin-left: 20px; /* Space between text and image */
  }
  
  
  .payment-cycle-list {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 1rem;
    color:  #333;
  }
  
  .payment-cycle-code {
    display: block;
    background-color: #FFEBCD;
    color:  #333;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid  #f0c014;
    margin: 10px 0;
    font-family: 'Courier New', Courier, monospace;
  }
  .create-account-container {
    max-width: 90%;  
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    background-color: #FFFAE3;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #333;
  }
  
  .create-account-title {
    font-size: 2.5rem;
    color: #f0c014;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .create-account-description {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .documents-section {
    background-color: #fef9e7;
    padding: 10px;
    border-left: 4px solid #f0c014;
    margin-bottom: 20px;
  }
  
  .gst-section {
    margin-bottom: 20px;
  }
  
  .gst-title {
    font-size: 1.5rem;
    color: #f0c014;
    margin-bottom: 10px;
  }
  
  .gst-description {
    margin-bottom: 10px;
  }
  
  .gst-steps {
    list-style-type: decimal;
    padding-left: 20px;
  }
  
  .gst-steps li {
    margin-bottom: 10px;
  }
  
  .categories-section {
    margin-bottom: 20px;
  }
  
  .categories-title {
    font-size: 1.5rem;
    color: #f0c014;
    margin-bottom: 10px;
  }
  
  .categories-description {
    margin-bottom: 20px;
  }
  
  .categories-list {
    columns: 2;
    column-gap: 20px;
    padding-left: 0;
    list-style-type: none;
  }
  
  .categories-list li {
    margin-bottom: 10px;
  }
  
  a {
    color: #f0c014;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .create-account-title {
      font-size: 2rem;
    }
    
    .categories-list {
      columns: 1;
    }
  }
  .list-products-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #333;
    max-width: 90%;  
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .content-section {
    flex: 1;
    margin-right: 20px;
  }
  
  .list-products-title {
    font-size: 2.5rem;
    color: #f0c014;
    margin-bottom: 20px;
  }
  
  .list-products-description {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .list-products-highlight {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .list-products-subheading {
    font-size: 1.2rem;
    color: #f0c014;
    margin-bottom: 10px;
  }
  
  .listing-methods {
    list-style-type: decimal;
    padding-left: 20px;
  }
  
  .listing-methods li {
    margin-bottom: 15px;
    font-size: 1rem;
  }
  
 
.idea-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
  
  @media (max-width: 768px) {
    .list-products-container {
      flex-direction: column;
      align-items: center;
    }
  
    .content-section {
      margin-right: 0;
      margin-bottom: 20px;
    }
  
  }
  .payment-cycle-img2 {
    max-width: 250px; /* Adjust the size of the image */
    height: auto;
    margin-right: 100px; /* Space between text and image */
  }
  .storage-shipping-container {
    background-color: #fff9e6;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #333;
    margin: 20px auto;
    max-width: 90%;  
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storage-shipping-title {
    font-size: 2.5rem;
    color: #f0c014;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .section-title {
    font-size: 1.8rem;
    color: #f0c014;
    margin-bottom: 20px;
    border-bottom: 2px solid #f0c014;
    padding-bottom: 10px;
  }
  
  .section-description {
    font-size: 1.2rem;
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .guidelines-list {
    list-style-type: disc;
    padding-left: 20px;
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .guidelines-list li {
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .storage-shipping-container {
      padding: 20px;
    }
  
    .storage-shipping-title {
      font-size: 2rem;
      margin-bottom: 20px;
    }
  
    .section-title {
      font-size: 1.5rem;
      margin-bottom: 15px;
    }
  
    .section-description {
      font-size: 1rem;
    }
  
    .guidelines-list {
      font-size: 0.9rem;
    }
  }
  .return-payment-container {
    background-color: #fff9e6;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #333;
    margin: 20px auto;
    max-width: 90%;  
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .return-payment-title {
    font-size: 2.5rem;
    color: #f0c014;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .section-title {
    font-size: 1.8rem;
    color: #f0c014;
    margin-bottom: 20px;
    border-bottom: 2px solid #f0c014;
    padding-bottom: 10px;
  }
  
  .section-description {
    font-size: 1.2rem;
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .process-list,
  .timelines-list {
    list-style-type: disc;
    padding-left: 20px;
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .process-list li,
  .timelines-list li {
    margin-bottom: 10px;
  }
  
  .timelines-list strong {
    color: #333;
  }
  
  @media (max-width: 768px) {
    .return-payment-container {
      padding: 20px;
    }
  
    .return-payment-title {
      font-size: 2rem;
      margin-bottom: 20px;
    }
  
    .section-title {
      font-size: 1.5rem;
      margin-bottom: 15px;
    }
  
    .section-description {
      font-size: 1rem;
    }
  
    .process-list,
    .timelines-list {
      font-size: 0.9rem;
    }
  }
  