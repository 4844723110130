.homeSlider {
  padding: 10px 0px;
}

.home_slider_Main {
  border-radius: 25px;
  overflow: hidden;
  position: relative;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); /* Center arrows vertically */
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background: rgb(210, 237, 255) !important;
  z-index: 1000 !important;
  transition: all 0.2s ease-in-out;
  display: flex; /* Center the icon within the arrow */
  align-items: center;
  justify-content: center;
}

.slick-arrow.slick-next {
  right: 10px; /* Place the next arrow 10px from the right */
}

.slick-arrow.slick-prev {
  left: 10px; /* Place the prev arrow 10px from the left */
}

.slick-prev:before,
.slick-next:before {
  font-family: FontAwesome !important;
  font-size: 20px !important;
  color: #a1b4b3 !important;
  content: ""; /* Remove default content */
}

/* Custom Arrow Icons with Rotation */
.slick-prev:after {
  content: "\f104"; /* FontAwesome icon code for left arrow */
  transform: rotate(135deg); /* Rotate left arrow by 135 degrees */
  display: block;
}

.slick-next:after {
  content: "\f105"; /* FontAwesome icon code for right arrow */
  transform: rotate(-45deg); /* Rotate right arrow by -45 degrees */
  display: block;
}

.slick-arrow:hover {
  background: rgb(226, 243, 255) !important;
}

.slick-arrow:hover:after {
  color: #f0db73 !important;
}

/* Optional: Adjust z-index if arrows are not clickable */
.slick-prev,
.slick-next {
  z-index: 1001;
}

/* Ensure FontAwesome is loaded */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css');

.bg-grey-100 {
  background-color: #f5f5f5; /* Light grey background */
}

.bg-white {
  background-color: #ffffff; /* White background */
}
.product .s1 {
  height: 94%;
  width: 100%;
  overflow: hidden;

}

.product .s1 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all 0.5s ease;
}

.product .s1 img:hover {
  scale: 1.1;
}
