.order-tracking {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .order-tracking h2 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333;
  }
  
  .order-tracking p {
    margin-bottom: 10px;
  }
  
  .order-tracking ul {
    list-style-type: none;
    padding: 0;
  }
  
  .order-tracking ul li {
    margin-bottom: 5px;
  }
  
  .order-tracking .action-buttons {
    margin-top: 20px;
  }
  
  .order-tracking .action-buttons button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .order-tracking .action-buttons button:hover {
    background-color: #0056b3;
  }
  
  .order-tracking .loading {
    text-align: center;
    font-size: 18px;
    color: #333;
    margin-top: 20px;
  }
  
  .order-tracking .error-message {
    color: #ff0000;
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
  }
  