  /* src/components/Promotion.css */
.promotion {
    padding: 20px;
  }
  
  .promotion-card, .new-promotion {
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ccc;
  }
  
  .promotion-card h3, .new-promotion h3 {
    margin-bottom: 10px;
  }
  
  .promotion-card img {
    max-width: 100px;
    max-height: 100px;
    margin-right: 10px;
  }
  
  .promotion-card div, .new-promotion div {
    margin-bottom: 10px;
  }
  
  .promotion-card input[type="text"], .new-promotion input[type="text"], 
  .promotion-card textarea, .new-promotion textarea {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
  
  .promotion-card button, .new-promotion button {
    margin-right: 10px;
  }
  