.allproducts{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.allproducts h1{
    font-size: 30px;
    display: flex;
    flex-direction: row;
    text-align: center;
}


.allproducts .products{
    display: flex;
    float: left;
    flex-wrap: wrap;
    justify-content: center;
    /* width: 30%; */
    flex-direction: row;
}

@media (max-width: 950px)
{
    .allproducts{
        width: 60%;
    }
    
}



@media (max-width: 650px)
{
    .allproducts{
        width: 100%;
    }
    
    
}